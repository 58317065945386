import { defineStore } from 'pinia'
import api from '@/modules/account/api'
import { useAccountStore } from '@/modules/account/store/accountStore'

export const usePendingPaymentStore = defineStore('pendingPayment', {
  state: () => ({
    pendingPayments: [],
    isPendingPaymentsLoading: false
  }),
  actions: {
    async searchPendingPayments ({ pageSize, pageNumber }) {
      this.isPendingPaymentsLoading = true

      try {
        const accountStore = useAccountStore()
        await accountStore.getAccountsForPayment()

        const fromIban = accountStore.paymentAccounts.map(acc => acc.iban)
        this.pendingPayments = await api.searchPendingPayments({ pageSize, pageNumber, fromIban })
      } catch (err) {
        this.$apm.captureError(err)
      } finally {
        this.isPendingPaymentsLoading = false
      }
    }
  }
})
