import { defineStore } from 'pinia'
import { request } from '@/plugins/utils'
import { isNull, pickBy, mapValues } from 'lodash'
import api from '@/modules/account/api'
import CustomerNotification from '@bigbank/dc-common/classes/customer-notification.class'

export const useAccountStore = defineStore('account', {
  state: () => ({
    details: null,
    accountDetails: null,
    countries: null,
    lookupCodes: {
      ACTIVITY: []
    },
    verificationData: {
      isF2fIdentified: null,
      limitAmount: null,
      availableAmount: null,
      usedAmount: null
    },
    notifications: [],
    paymentAccounts: [],
    paymentAccountsLoading: false
  }),
  getters: {
    countryList: (state) => state.countries
  },
  actions: {
    async getAccountsForPayment (forceReload = false) {
      if (!this.paymentAccounts || forceReload) {
        this.paymentAccountsLoading = true
        try {
          this.paymentAccounts = await api.getAccountsForPayment()
        } finally {
          this.paymentAccountsLoading = false
        }
      }
    },
    async getDetails (forceReload = true) {
      if (this.details && !forceReload) {
        return Promise.resolve()
      }

      this.details = await request('/account/api/details')
    },
    async getAccountDetails (forceReload = true) {
      if (this.accountDetails && !forceReload) {
        return Promise.resolve()
      }

      try {
        this.accountDetails = await request('/account/api/id/details', {
          errHandlerOpts: { handleOnlyKnownErrors: true }
        })
      } catch {
        // Ignore errors
      }
    },
    async getVerificationData ({ forceReload = false } = {}) {
      if (!isNull(this.verificationData.isF2fIdentified) && !forceReload) {
        return Promise.resolve()
      }

      this.verificationData = await api.getAccountVerificationStatus()
    },
    async updateDetails (updateRequest) {
      const response = await request('/account/api/details', {
        method: 'put',
        body: updateRequest,
        errHandlerOpts: {
          throwOnAllErrors: true
        }
      })
      await this.getDetails()

      return response
    },
    async uploadDocument ({ files, ...data }) {
      const formData = new FormData()

      files.forEach(f => {
        formData.append('file', f.file)
      })

      Object.keys(data).forEach(key => {
        formData.append(key, data[key])
      })

      await request('/account/api/document', {
        method: 'post',
        body: formData,
        contentType: `multipart/form-data; boundary=${formData._boundary}`
      })

      await this.getDetails()
    },
    async getCountries (refresh = false) {
      if (this.countries && !refresh) {
        return Promise.resolve()
      }

      this.countries = await request('/account/api/meta/countries')
    },
    async initPasswordChange (changeRequest) {
      return await request('/account/api/id/init-password-change', {
        method: 'post',
        body: changeRequest,
        errHandlerOpts: { handleOnlyKnownErrors: true }
      })
    },
    async finalizePasswordChange (otp) {
      return await request('/account/api/id/finalize-password-change', {
        method: 'post',
        body: { otp },
        errHandlerOpts: { handleOnlyKnownErrors: true }
      })
    },
    async initPasswordSet (changeRequest) {
      return await request('/account/api/id/init-set-password', {
        method: 'post',
        body: changeRequest,
        errHandlerOpts: { handleOnlyKnownErrors: true }
      })
    },
    async finalizePasswordSet (otp) {
      return await request('/account/api/id/finalize-set-password', {
        method: 'post',
        body: { otp },
        errHandlerOpts: {
          handleOnlyKnownErrors: true
        }
      })
    },
    async setNotifications (notifications) {
      this.notifications = pickBy(mapValues(notifications, ({ options, attributes }) => {
        try {
          return new CustomerNotification(options, attributes)
        } catch (err) {
          const notificationsStringified = JSON.stringify(notifications, null, 2)
          this._vm.$apm.captureError(`${err.message} \n ${err.stack} \n ${notificationsStringified}`)
          return null
        }
      }), (notice) => !isNull(notice))
    }
  }
})
