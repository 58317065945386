<script>
import { mapState, mapActions } from 'pinia'
import ErrorModal from './components/ErrorModal'
import InactivityModal from './components/InactivityModal'
import OpenSavingsDepositModal from './components/OpenSavingsDepositModal'
import Banner from './components/LoggedInAsBanner'
import NavigationSecurityChecksMixin from '@/mixins/navigationSecurityChecksMixin'
import { useRootStore } from './store/root'

export default {
  name: 'bb-chrome',
  components: { ErrorModal, InactivityModal, OpenSavingsDepositModal, Banner },
  mixins: [NavigationSecurityChecksMixin],
  render: function (createElement) {
    const loading = ['layout-full-page', { props: { loading: true } }]
    const router = ['app-view', { props: { key: this.$route.fullPath } }]
    const errorModal = this.errorModal
      ? createElement('error-modal', {
        props: { ...this.errorModal },
        on: { close: this.hideErrorModal }
      })
      : null
    const inactivityModal = createElement('inactivity-modal')
    const openSavingsDepositModal = createElement('open-savings-deposit-modal')
    const mainLayout = createElement(...(this.isInitialAuthCheckMade && !this.isLoading ? router : loading))
    const notification = createElement('notifications')
    const banner = createElement('banner')

    return createElement('div', [
      mainLayout,
      errorModal,
      inactivityModal,
      openSavingsDepositModal,
      banner,
      notification
    ])
  },
  data () {
    return {
      showLoginModal: false
    }
  },
  async mounted () {
    this.$language.current = this.language
    this.$validator.localize(this.language)
    this.setDocumentTitle()
    if (window.MAINTENANCE_MODE_ENABLED) {
      this.$router.push('/oops/maintenance')
    }
    this.checkSession()
  },
  methods: {
    ...mapActions(useRootStore, [
      'checkSession',
      'getContractsCount',
      'hideErrorModal',
      'setIsMobile'
    ]),
    bubbleScroll (event) {
      event.target.dispatchEvent(new event.constructor('bubbleScroll', { ...event, bubbles: true }))
    },
    handleWindowResize () {
      this.setIsMobile(window.innerWidth < 992)
    },
    setDocumentTitle () {
      document.title = this.$gettext('Self-service | Bigbank')
    }
  },
  watch: {
    areTermsAccepted: function (accepted) {
      if (!accepted) return
      this.getContractsCount()
    },
    $route (to, from) {
      this.activeRoute = to
      if (this.activeRoute.path.startsWith('/oops')) {
        this.activeRoute = from
      }
    },
    language () {
      this.setDocumentTitle()
    }
  },
  computed: {
    ...mapState(useRootStore, [
      'isLoggedIn', 'contractsCount', 'areTermsAccepted',
      'isInitialAuthCheckMade', 'showTransactionalErrorModal',
      'featureFlags', 'language',
      'errorModal', 'isLoading', 'channelConfig'
    ])
  },
  created () {
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)

    this.addUnloadEventListener()
    this.setupServiceWorkerStatusListener()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleWindowResize)
  }
}
</script>

<style lang="scss">
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }

  section {
    z-index: 0 !important;
  }

  body {
    margin: 0;
    min-height: 100vh;
    background-color: $gray-10;

    @media (min-width: $mobile-view-breaking-point) {
      background-color: unset;
    }
  }

  .app-container {
    display: flex;
    flex-direction: column;
    background-color: $gray-10;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;

    @media (min-width: $desktop-wide-bp) {
      flex-direction: row;
      overflow: scroll;
    }

    &__menu {
      flex: 0 0 45px;
      order: 2;
      background-color: white;
      z-index: 5;

      @media (min-width: $desktop-wide-bp) {
        order: 1;
        flex: 0 1 290px;
        min-height: 100vh;
        max-width: 290px;
      }
    }

    &__content {
      flex: 1 0 0;
      box-sizing: border-box;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      -webkit-overflow-scrolling: touch;

      @media (min-width: $desktop-view-breaking-point) {
        display: flex;
        order: 2;
        max-height: 100vh;
        overflow-y: scroll;
        height: unset;
      }

      &__filler {
        @media (min-width: $desktop-view-breaking-point) {
          height: 100%;
        }
      }

      &-main {
        flex: 1 0 0;
        border-bottom: 1px solid $gray-40;
      }
    }

    &__footer {
      margin-top: 30px;
    }
  }

  .application-wrapper {
    min-height: 100vh;
  }

  .route-wrapper {
    margin-top: -50px;

    @media (min-width: $desktop-wide-bp) {
      max-width: 650px;
      margin: -80px auto 0;
    }
  }

  main {
    text-align: center;
  }

  .drawer-title {
    width: 100%;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;

    &--btn {
      width: 32px;
      height: 32px;
    }
  }

  .hide-desktop {
    @media (min-width: $desktop-wide-bp) {
      display: none;
    }
  }

  .drawer__dialog--xs {
    @media (min-width: $tablet-bp) {
      width: auto;
    }

    .drawer__header {
      height: 70px;
    }

    .drawer__body {
      height: 100%;
      padding: 0;
    }
  }

  .footer__contacts {
    @media (max-width: $mobile-view-breaking-point) {
      max-width: 100vw;

      > div:last-child {
        max-width: 320px;
      }
    }
  }
</style>
