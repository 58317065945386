<template>
  <div class="payments__main">
    <signing-modal
      ref="signing"
      :initMethod="initPaymentSigning"
      @signingSuccessful="onSuccessfulPaymentSigning"
      @signingCancelled="onPaymentSigningCancelled"
    />
    <template v-if="isPaymentResultShown">
      <payment-result/>
    </template>
    <template v-else>
      <bb-form @progress="onProgress" :loading="isLoading" :disabled="isLoading">
        <div class="payments__heading" id="payment-heading">{{ translations.heading }}</div>
        <bb-select
          framed
          :label="translations.account"
          name="account"
          v-model="form.accountId"
          :options="accountOptions"
          v-validate="'required|unique-beneficiary-iban'"
          :data-vv-as="translations.account"
          :placeholder="translations.selectAccount"
          data-testid="payment-view-account-select"
        />
        <bb-select
          v-if="showPredefinedPayments"
          framed
          :label="translations.selectPredefined"
          name="predefinedPayment"
          v-model="predefinedPaymentId"
          @change="onPredefinedPaymentChange"
          :options="predefinedPaymentOptions"
          placeholder="-"
          data-testid="payment-view-predefined-select"
        />
        <bb-input
          framed
          :label="translations.recipientName"
          name="recipientName"
          v-validate="'required|spaceCannotBeFirstChar|beneficiary-name|beneficiary-name-length'"
          v-model="form.recipientName"
          :loading="beneficiaryValidation.isLoading"
          :data-vv-as="translations.recipientName"
          data-testid="payment-view-recipient-name-input"
          data-vv-validate-on="change|input"
        />
        <bb-input
          framed
          :label="translations.recipientIban"
          :formatter="formatIban"
          name="recipientIban"
          v-validate="'required|iban|beneficiary-iban|unique-beneficiary-iban'"
          v-model.trim="form.recipientIban"
          :loading="beneficiaryValidation.isLoading"
          :data-vv-as="translations.recipientIban"
          data-testid="payment-view-recipient-iban-input"
          data-vv-validate-on="change|input"
        />
        <bb-input
          framed
          :label="translations.amount"
          v-validate="amountRules"
          :data-vv-as="translations.amount"
          v-model="form.amount"
          name="amount"
          currency
          :locale="locale"
          :add-on="currencySymbol"
          data-vv-validate-on="change|input"
          data-testid="payment-view-amount"
          :inputAttrs="{autocomplete: 'off'}"
        />
        <div
          v-if="availableBalance !== null"
          class="payments__available-balance"
          :class="amountErrorCssClasses"
          data-testid="payment-view-available-balance-text"
        >
          {{ availableBalanceFormatted }}
        </div>
        <bb-banner
          v-if="hasFfLimitError"
          class="m-t-15 m-b-5"
          type="error"
          :title="translations.errors.ffLimit"
          permanent
          visible
        >
          <div class="f-color-gray">{{ translations.verifyAccount.description }}</div>
          <bb-button @click="onIdentify" class="m-t-5" color="gray" size="sm" inverted>
            {{ translations.verifyAccount.button }}
          </bb-button>
        </bb-banner>
        <bb-banner
          v-else-if="hasLimitsError"
          class="m-t-15 m-b-5"
          type="error"
          :title="translations.errors.limits[this.limitError.limitPeriodType]"
          permanent
          visible
        >
          <div class="f-color-gray">{{ translations.updateLimits[this.limitError.limitPeriodType] }}</div>
          <bb-button v-if="isUpdateLimitsButtonVisible" @click="onUpdateLimits" class="m-t-5" color="gray" size="sm"
                     inverted>{{ translations.updateLimits.button }}
          </bb-button>
        </bb-banner>
        <bb-input
          framed
          :label="translations.description"
          :data-vv-as="translations.description"
          name="description"
          ref="description"
          v-model="form.description"
          v-validate="'spaceCannotBeFirstChar|descriptionCanContainOnlyFollowingsCharacters|descriptionLength'"
          v-validate.continues="'atLeastOneFilled:referenceNumber'"
          data-testid="payment-view-description"
        />
        <bb-input
          framed
          type="text"
          :label="translations.referenceNumber"
          :data-vv-as="translations.referenceNumber"
          :maxlength="maxInputLengths.reference"
          name="referenceNumber"
          ref="referenceNumber"
          v-model.trim="form.referenceNumber"
          v-validate.continues="'atLeastOneFilled:description|referenceCorrectness'"
          data-testid="payment-view-reference-number"
        />
        <div slot="submit" slot-scope="{}"/>
      </bb-form>
      <bb-button data-testid="payment-submit" display="block" v-bind="button" class="m-t-40" @click="submit">
        {{ translations.submit }}
      </bb-button>
    </template>
  </div>
</template>

<script>
import { isNil } from 'lodash'
import SigningModal from '../../components/signing/SigningModal'
import PaymentResult from './PaymentResult'
import { PaymentStatus } from './const'
import { mapActions, mapState } from 'pinia'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import api from '../../api'
import { usePaymentStore } from '@account/store/paymentStore'
import { useAccountStore } from '@account/store/accountStore'
import { AccountRouteName } from '@account/const'
import { AuthMethod } from '@bigbank/dc-common/config'
import paymentsMixin from '@account/views/payments/payments.mixin'
import { PAYMENT, PREFILL_SOURCE } from '@/TrackingActions'
import isNumeric from 'fast-isnumeric'

export default {
  components: {
    SigningModal,
    PaymentResult
  },
  mixins: [paymentsMixin],
  data () {
    return {
      transactionId: null,
      limitError: {
        limitPeriodType: 'DAILY'
      },
      limits: {}
    }
  },
  watch: {
    'shouldResetPaymentForm' (value) {
      if (value === true) {
        this.getAccounts(true)
        this.form = {
          ...this.paymentDetails,
          createdTime: undefined
        }
        this.setShouldResetPaymentForm(false)
      }
    },
    $route: {
      immediate: true,
      handler (to) {
        if (to.query.transactionId) {
          this.setTransactionId(to.query.transactionId)
        }
      }
    },
    transactionId: {
      immediate: true,
      handler: async function (newId, oldId) {
        if (!isNil(newId) && newId !== oldId && Number(newId) > 0) {
          this.getTransactionDetails()
        }
      }
    }
  },
  computed: {
    ...mapState(usePaymentStore, ['paymentStatus', 'shouldResetPaymentForm']),
    ...mapState(useAccountStore, ['verificationData']),
    predefinedPaymentOptions () {
      if (!this.predefinedPayments) {
        return []
      }

      return this.predefinedPayments.map(payment => ({
        text: payment.name,
        value: payment.id
      }))
    },
    showPredefinedPayments () {
      return this.predefinedPayments.length !== 0
    },
    translations () {
      return {
        heading: this.$pgettext('payment_form', 'Outgoing payment'),
        account: this.$pgettext('payment_form', 'From account'),
        recipientName: this.$pgettext('payment_form', 'Recipient name'),
        recipientIban: this.$pgettext('payment_form', 'Recipient IBAN'),
        amount: this.$pgettext('payment_form', 'Amount'),
        description: this.$pgettext('payment_form', 'Description'),
        referenceNumber: this.$pgettext('payment_form', 'Reference number'),
        submit: this.$pgettext('payment_form', 'Send money'),
        availableBalance: this.$pgettext('payment_form', 'Available balance: %'),
        selectAccount: this.$pgettext('payment_form', 'Select account you wish to transfer from'),
        selectPredefined: this.$pgettext('payment_form', 'Predefined payment'),
        updateLimits: {
          DAILY: this.$pgettext('payment_form', 'Update your daily limit to proceed with this operation'),
          MONTHLY: this.$pgettext('payment_form', 'Update your monthly limit to proceed with this operation'),
          BOTH: this.$pgettext('payment_form', 'Update your daily and monthly limit to proceed with this operation'),
          button: this.$pgettext('payment_form', 'Increase limit')
        },
        verifyAccount: {
          description: this.$gettextInterpolate(this.$pgettext('payment_form', 'In order to make payments you need to identify yourself. Current remaining limit is %{amount}.'), {
            amount: formatMoneyWithCurrency(this.verificationData.availableAmount, this.currency, this.locale)
          }),
          button: this.$pgettext('payment_form', 'Identify')
        },
        errors: {
          limits: {
            DAILY: this.$pgettext('payment_form', 'Daily limit exceeded'),
            MONTHLY: this.$pgettext('payment_form', 'Monthly limit exceeded'),
            BOTH: this.$pgettext('payment_form', 'Daily and monthly limit exceeded')
          },
          ffLimit: this.$pgettext('payment_form', 'Monthly payout limit of €15,000 exceeded')
        }
      }
    },
    amountRules () {
      const hasSelectedAccount = !!this.form.accountId

      return {
        required: true,
        ffLimit: true,
        limits: hasSelectedAccount,
        amountSize: hasSelectedAccount ? this.availableBalance : false
      }
    },
    availableBalance () {
      if (!this.form.accountId) {
        return null
      }

      return this.selectedAccount?.availableBalance ?? 0
    },
    availableBalanceFormatted () {
      return this.translations.availableBalance.replace('%', formatMoneyWithCurrency(this.availableBalance, this.currency, this.locale))
    },
    hasAmountError () {
      const value = parseFloat(this.form.amount)

      return value > 0 && value > this.availableBalance
    },
    amountErrorCssClasses () {
      return { 'f-color-red': this.hasAmountError }
    },
    isPaymentResultShown () {
      return Object.values(PaymentStatus).includes(this.paymentStatus)
    },
    hasLimitsError () {
      return this.$validator.errors
        .collect('amount', undefined, false)
        .some(error => error.rule === 'limits')
    },
    hasFfLimitError () {
      return this.$validator.errors
        .collect('amount', undefined, false)
        .some(error => error.rule === 'ffLimit')
    },
    isUpdateLimitsButtonVisible () {
      return this.featureFlags.enableAccountsLimitsTab
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'resetPaymentDetails',
      'redirectToPaymentForm',
      'setPaymentStatus',
      'updatePaymentDetails',
      'setShouldResetPaymentForm',
      'fetchPaymentToState'
    ]),
    ...mapActions(useAccountStore, ['getVerificationData']),
    async onPredefinedPaymentChange (predefinedPaymentId) {
      if (predefinedPaymentId) {
        this.$tracker.action(PAYMENT.PREFILLED, { prefillSource: PREFILL_SOURCE.DROPDOWN })
        this.$router.push({
          name: AccountRouteName.OutgoingPayments,
          query: { predefinedPaymentId }
        })
      } else {
        this.$router.push({ name: AccountRouteName.OutgoingPayments })
      }
    },
    setTransactionId (id) {
      this.transactionId = Number(id)
    },
    async onSuccessfulPaymentSigning (signingResponse) {
      const paymentId = signingResponse.paymentId
      try {
        // Fetched for Eparaksts
        if (isNumeric(paymentId)) {
          await this.fetchPaymentToState(paymentId)
        }
        this.setPaymentStatus(PaymentStatus.Success)
      } catch (_err) {
        this.onPaymentSigningCancelled()
      }
    },
    async onPaymentSigningCancelled (signingResponse) {
      const paymentId = signingResponse.paymentId
      // Fetched for Eparaksts
      if (isNumeric(paymentId)) {
        await this.fetchPaymentToState(paymentId)
      }
      this.setPaymentStatus(PaymentStatus.Failure)
    },
    trackInit () {
      const prefillSource = this.transactionId
        ? PREFILL_SOURCE.TRANSACTION
        : this.predefinedPaymentId
          ? PREFILL_SOURCE.PREDEFINED_PAYMENT
          : PREFILL_SOURCE.NONE
      this.$tracker.action(PAYMENT.INITIATED, { prefillSource })
    },
    async initPaymentSigning () {
      try {
        const response = await api.initPayment(this.form)
        this.getAccounts(true)
        this.trackInit()
        this.updatePaymentDetails({ createdTime: response.createdTime })
        return response
      } catch (e) {
        this.setPaymentStatus(PaymentStatus.Failure)
        this.isLoading = false
        return { method: e.data.signingMethod }
      }
    },
    async submit () {
      this.beneficiaryValidation.isFormSubmitting = true
      if (this.isLoading || !await this.$validator.validate() || !await this.validateLimits()) {
        return
      }

      try {
        this.loaders.submit = true
        this.updatePaymentDetails({ ...this.form })
        this.$refs.signing.signButtonClick(new MouseEvent('click', {}))
      } finally {
        this.beneficiaryValidation.isFormSubmitting = false
        this.loaders.submit = false
      }
    },
    async validateLimits () {
      if (!this.form.accountId || !this.form.amount || this.isValidationLoading) {
        return
      }
      try {
        this.isValidationLoading = true

        const [limits] = await Promise.all([
          api.getAccountLimits(this.form.accountId),
          this.getVerificationData({ forceReload: true })
        ])

        this.limits[this.form.accountId] = limits

        return await this.$validator.validate('amount')
      } finally {
        this.isValidationLoading = false
      }
    },
    onIdentify () {
      this.$router.push({ name: AccountRouteName.AccountVerification })
    },
    onUpdateLimits () {
      this.$router.push({ name: AccountRouteName.AccountsLimits })
    },
    async getTransactionDetails () {
      this.loaders.transactionDetails = true
      this.prefilledData = await api.getTransactionDetails(this.transactionId)
      this.loaders.transactionDetails = false
    }
  },
  async mounted () {
    await this.resetPaymentDetails()
    await this.redirectToPaymentForm()
    if (this.featureFlags.enablePredefinedPayments && this.predefinedPayments.length === 0) {
      await this.getPredefinedPayments()
    }
    await this.prefillForm()
    document.querySelector('#payment-layout-title').scrollIntoView()
  },
  created () {
    this.$validator.extend('amountSize', {
      getMessage: () => null,
      validate: (value, [maxAmount]) => {
        return parseFloat(value) > 0 && parseFloat(value) <= maxAmount
      }
    })
    this.$validator.extend('ffLimit', {
      getMessage: () => null,
      validate: () => {
        const paymentAmount = parseFloat(this.form.amount)

        if (!this.featureFlags.enableUnverifiedCustomerAdditionalLimits) {
          return true
        }

        if (this.verificationData.isF2fIdentified === false && this.verificationData.availableAmount < paymentAmount) {
          return false
        }

        return true
      }
    })
    this.$validator.extend('limits', {
      getMessage: () => null,
      validate: () => {
        if (!this.form.accountId) {
          return true
        }

        const paymentAmount = parseFloat(this.form.amount)
        const limits = this.limits[this.form.accountId]

        if (!limits) {
          return true
        }

        const failingLimits = limits.filter(limit => paymentAmount > limit.remainingLimit)

        if (failingLimits.length > 0) {
          this.limitError = {
            limitPeriodType: failingLimits.length > 1 ? 'BOTH' : failingLimits[0].limitPeriodType
          }
        }

        return !failingLimits.length
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.payments {
  &__main {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @media (min-width: $desktop-view-breaking-point) {
      max-width: 425px;
      min-width: 425px;
      width: auto;
    }
  }

  &__heading {
    color: $gray;
    font-size: $font-size-small;
    font-family: $gotham-bold;
    font-weight: 400;
  }

  &__available-balance {
    margin-top: 2px;
    font-size: $font-size-smallest
  }
}
</style>
