<template>
  <schedule v-bind="scheduleProps" />
</template>

<script>
import Schedule from '@loan/components/ScheduleTemplate'
import api from '@loan/api'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { componentFeatureRouteGuard } from '@/plugins/routeGuards'

export default {
  name: 'loan-schedule',
  components: { Schedule },
  data () {
    return {
      contractId: null
    }
  },
  computed: {
    ...mapState(useLoanStore, ['contract']),
    scheduleProps () {
      return {
        loadData: this.loadData,
        contractId: this.contractId,
        contract: this.contract
      }
    }
  },
  methods: {
    loadData () {
      return api.getFullSchedule(this.contractId)
    }
  },
  created () {
    this.contractId = this.$route.params.contractId
  },
  beforeRouteEnter: componentFeatureRouteGuard(['enableContractDetails', 'enableContractDetailsForRevolvingLoan'], undefined, 'loan')
}
</script>
