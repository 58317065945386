<template>
  <div>
    <template v-if="isClosedTagOnAccountStatementPageEnabled">
      <bb-select
        v-model="val"
        :name="`account-${type}-id`"
        :disabled="isLoading"
        framed
        :placeholder="isCard ? translations.noCardSelected : translations.noContractSelected"
        class="p-0"
      >
        <optgroup
          v-if="computedOptions.active.length > 0"
          :label="translations.active"
        >
          <option
            v-for="option in computedOptions.active"
            :key="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </optgroup>
        <optgroup
          v-if="computedOptions.blocked.length > 0"
          :label="translations.blocked"
        >
          <option
            v-for="option in computedOptions.blocked"
            :key="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </optgroup>
        <optgroup
          v-if="computedOptions.closed.length > 0"
          :label="translations.closed"
        >
          <option
            v-for="option in computedOptions.closed"
            :key="option.text"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </optgroup>
        <template #suffix="{ value }">
          <bb-label
            v-if="computedOptions.closed.find(option => option.value === value)"
            :value="translations.closed"
            size="18"
            class="m-l-10 bg-red f-smallest"
          />
        </template>
      </bb-select>
    </template>
    <template v-else>
      <bb-select
        v-model="val"
        :name="`account-${type}-id`"
        :disabled="isLoading"
        framed
        :placeholder="translations.noContractSelected"
        class="p-0"
        :options="computedOptions.default"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useRootStore } from '../../store/root'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import CardTypeTranslationMixin from '@credit-card/components/mixins/CardTypeTranslationMixin'
import { AccountStatusCode } from '@bigbank/dc-common/clients/http/account/account.enums'

export default {
  name: 'transactions-filter-contract-select',
  mixins: [
    CardTypeTranslationMixin
  ],
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      required: true,
      validator: v => ['card', 'contract'].includes(v)
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    value (newValue) {
      this.val = newValue
    },
    val (newValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {
    ...mapState(useRootStore, ['locale', 'featureFlags']),
    isCard () {
      return this.type === 'card'
    },
    translations () {
      return {
        noCardSelected: this.$pgettext('card_transactions_search', 'No card selected'),
        noContractSelected: this.$pgettext('card_transactions_search', 'No contract selected'),
        active: this.$pgettext('EDB_account_status', 'Active'),
        blocked: this.$pgettext('EDB_account_status', 'Blocked'),
        closed: this.$pgettext('EDB_account_status', 'Closed')
      }
    },
    isClosedTagOnAccountStatementPageEnabled () {
      return this.featureFlags.enableClosedTagOnAccountStatementPage
    },
    computedOptions () {
      const options = this.options || []
      const sortedOptions = {
        active: [],
        closed: [],
        blocked: [],
        default: []
      }

      for (const option of options) {
        if (!this.isClosedTagOnAccountStatementPageEnabled) {
          sortedOptions.default.push(this.createOption(option))
          continue
        }

        if (option.statusCode === AccountStatusCode.Active) {
          sortedOptions.active.push(this.createOption(option))
        } else if (option.statusCode === AccountStatusCode.Blocked) {
          sortedOptions.blocked.push(this.createOption(option))
        } else {
          sortedOptions.closed.push(this.createOption(option))
        }
      }
      return sortedOptions
    }
  },
  methods: {
    formatMoneyWithCurrency,
    getAccountTypeName (accountTypeName) {
      return this.translations.accountTypeName[accountTypeName] ?? accountTypeName
    },
    createOption (option) {
      return {
        value: option.id,
        text: this.isCard ? [this.cardTypeLabel(option), option.maskedPan].join(' | ') : option.contractNumber
      }
    }
  }
}
</script>
