<template>
  <div>
    <bb-input
      v-model="filterString"
      name="payment-search"
      data-testid="payment-search"
      :placeholder="translations.searchPaymentPlaceholder"
      framed class="m-t-10"/>
    <bb-public-table
      :columns="tableColumns"
      :rows="tableRows"
      has-actions
      class="bg-gray-10 m-t-15"
    >
      <template #cell="{ columnIndex, row, value }">
        <template v-if="$screen.md">
          <p class="f-gotham-bold f-color-gray f-small break-long-word">
            {{ typeof value === 'string' ? value : value.primary }}
          </p>
          <p
            v-if="value.secondary"
            class="f-gotham-bold f-color-gray-70 f-tiny break-long-word"
          >
            {{ value.secondary }}
          </p>
        </template>
        <div
          v-else
          class="d-flex justify-content-between"
        >
          <template v-if="columnIndex === 0">
            <div>
              <p
                :aria-label="tableColumns[0].label"
                class="f-gotham-medium f-color-gray f-small break-long-word"
              >
                {{ row.paymentName }}
              </p>
              <p
                :aria-label="tableColumns[2].label"
                class="f-gotham-bold f-color-gray-70 f-tiny break-long-word"
              >
                {{ row.description.primary }}
              </p>
              <p
                :aria-label="tableColumns[2].label"
                class="f-gotham-bold f-color-gray-70 f-tiny"
              >
                {{ row.description.secondary }}
              </p>
            </div>
            <p
              :aria-label="tableColumns[3].label"
              class="m-l-10 f-nowrap f-gotham-bold f-color-gray f-small"
            >
              {{ row.amount }}
            </p>
          </template>
          <template v-else-if="columnIndex === 1">
            <p
              :id="`beneficiary-label-${row.id}`"
              class="f-gotham-medium f-color-gray f-smallest"
            >
              {{ translations.recipientName }}
            </p>
            <p
              :aria-labelledby="`beneficiary-label-${row.id}`"
              class="m-l-10 f-align-right f-gotham-bold f-color-gray f-small break-long-word"
            >
              {{ row.beneficiary.primary }}
            </p>
          </template>
          <div v-else/>
        </div>
      </template>
      <template #actions="{ row }">
        <div class="d-flex justify-content-between justify-content-md-end">
          <bb-button
            data-testid="predefined-pay-button"
            color="mint"
            size="sm"
            :label="translations.payButton"
            class="flex-grow-1 flex-md-grow-0"
            @click="onPay(row.id)"
          />
          <bb-button
            data-testid="predefined-edit-button"
            color="black"
            size="sm"
            :display="$screen.md ? 'square' : null"
            :icon="$screen.md ? 'ui-pencil' : null"
            :label="$screen.md ? '' : translations.editButton"
            :aria-label="translations.editButton"
            inverted
            class="flex-grow-1 flex-md-grow-0 m-l-10"
            @click="onEdit(row.id)"
          />
          <bb-button
            data-testid="predefined-delete-button"
            color="black"
            size="sm"
            :display="$screen.md ? 'square' : null"
            :icon="$screen.md ? 'ui-delete' : null"
            :label="$screen.md ? '' : translations.deleteButton"
            :aria-label="translations.deleteButton"
            inverted
            class="flex-grow-1 flex-md-grow-0 m-l-10"
            @click="onDelete(row.id)"
          />
        </div>
      </template>
    </bb-public-table>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { usePredefinedPaymentStore } from '@account/store/predefinedPaymentStore'
import {
  PredefinedPaymentType
} from '@bigbank/dc-common/clients/http/account/modules/predefined-payments/predefined-payments.enums'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { useRootStore } from '@/store/root'
import { AccountRouteName } from '@account/const'
import { pick } from 'lodash'
import { PAYMENT, PREFILL_SOURCE } from '@/TrackingActions'

const searchByString = (filterString) => (payment) => {
  if (filterString) {
    const fieldsToSearch = pick(payment, ['name', 'recipientName', 'recipientName', 'recipientIban', 'description', 'referenceNumber', 'amount'])
    return Object.values(fieldsToSearch).some(value =>
      value?.toString()?.toLowerCase().includes(filterString.toLowerCase())
    )
  } else {
    return true
  }
}

export default {
  name: 'predefined-payment-table',
  data () {
    return {
      filterString: ''
    }
  },
  computed: {
    ...mapState(usePredefinedPaymentStore, ['predefinedPayments', 'predefinedPaymentsLoading']),
    ...mapState(useRootStore, ['locale']),
    tableColumns () {
      return [
        {
          key: 'paymentName',
          label: this.$pgettext('predefined_payment', 'Predefined payment name')
        },
        {
          key: 'beneficiary',
          label: `${this.$pgettext('payment_form', 'Recipient name')} / ${this.$pgettext('payment_form', 'Recipient IBAN')}`
        },
        {
          key: 'description',
          label: `${this.$pgettext('payment_form', 'Description')} / ${this.$pgettext('payment_form', 'Reference number')}`,
          class: 'hidden-mobile'
        },
        {
          key: 'amount',
          label: this.$pgettext('payment_form', 'Amount'),
          class: 'hidden-mobile f-align-right-md f-nowrap'
        }
      ]
    },
    tableRows () {
      return this.predefinedPayments.filter(payment => payment.paymentType === PredefinedPaymentType.EUROPEAN)
        .filter(searchByString(this.filterString)).map(payment => ({
          id: payment.id,
          paymentName: payment.name,
          beneficiary: {
            primary: payment.recipientName,
            secondary: payment.recipientIban
          },
          description: {
            primary: payment.description || '-',
            secondary: payment.referenceNumber || '-'
          },
          amount: formatMoneyWithCurrency(payment?.amount ?? '-', payment.currencyCode, this.locale)
        }))
    },
    translations () {
      return {
        recipientName: this.$pgettext('predefined_payment_table', 'Recipient name'),
        searchPaymentPlaceholder: this.$pgettext('predefined_payment_table', 'Search payments'),
        payButton: this.$pgettext('predefined_payment_table', 'Pay'),
        editButton: this.$pgettext('predefined_payment_table', 'Edit'),
        deleteButton: this.$pgettext('predefined_payment_table', 'Delete')
      }
    }
  },
  methods: {
    onPay (predefinedPaymentId) {
      this.$tracker.action(PAYMENT.PREFILLED, { prefillSource: PREFILL_SOURCE.LIST })
      this.$router.push({
        name: AccountRouteName.OutgoingPayments,
        query: { predefinedPaymentId }
      })
    },
    onEdit (predefinedPaymentId) {
      this.$router.push({
        name: AccountRouteName.PredefinedPayments,
        query: { predefinedPaymentId }
      })
    },
    onDelete (predefinedPaymentId) {
      this.$emit('onDelete', predefinedPaymentId)
    }
  }
}
</script>

<style lang="scss" scoped>
.break-long-word {
  word-break: break-all;
}
</style>
