<template>
  <div>
    <h4 class='f-gotham-bold f-color-navy align-center'
        :data-testid="isResultSuccess ? 'payment-success': 'payment-failure'"
    >{{ titleText }}</h4>
    <h3 :class="`f-color-${paymentAmountColor} align-center`">{{ formattedPaymentAmount }}</h3>
    <bb-list-item
      v-if="predefinedPaymentId"
      class="m-t-50 break-long-word"
      :title="translations.predefinedPaymentName"
      no-top-border
      condensed
      :titleUppercase="false"
      title-nowrap
      :slot-nowrap="false"
    >
      {{ predefinedPaymentName }}
    </bb-list-item>
    <bb-list-item
        :class="predefinedPaymentId === '' ? 'm-t-50 break-long-word': 'break-long-word'"
        :title="translations.recipientName"
        :no-top-border="predefinedPaymentId === ''"
        condensed
        :titleUppercase="false"
        title-nowrap
        :slot-nowrap="false"
    >
      {{ paymentRequest.recipientName }}
    </bb-list-item>
    <bb-list-item
        :title="translations.recipientIban"
        condensed
        :titleUppercase="false"
    >
      {{ paymentRequest.recipientIban }}
    </bb-list-item>
    <bb-list-item
        :title="translations.time"
        condensed
        :titleUppercase="false"
    >
      {{ formattedTime }}
    </bb-list-item>
    <bb-list-item
      class="break-long-word"
      :title="translations.description"
      condensed
      :titleUppercase="false"
      title-nowrap
      :slot-nowrap="false"
    >
      {{ paymentRequest.description }}
    </bb-list-item>
    <bb-list-item
        :title="translations.referenceNumber"
        condensed
        :titleUppercase="false"
    >
      {{ paymentRequest.referenceNumber }}
    </bb-list-item>
    <bb-button
      v-if="isSaveTransactionAsButtonVisible"
      data-testid="save-transaction-button"
      display="block"
      color="blue"
      inverted
      class="m-t-40"
      @click="handleTransactionSaving"
    >
      {{ saveButtonText }}
    </bb-button>
    <bb-button
      v-else-if="!isResultSuccess"
      data-testid="start-new-transaction-button"
      display="block"
      color="blue"
      inverted
      class="m-t-40"
      @click="handleNewTransaction"
    >
      {{ translations.startNewTransaction }}
    </bb-button>
    <bb-button
      v-if="isResultSuccess"
      data-testid="start-new-transaction-button"
      display="block"
      class="m-t-15"
      @click="handleNewTransaction"
    >
      {{ translations.startNewTransaction }}
    </bb-button>
    <bb-button
      v-else
      data-testid="transaction-retry-button"
      display="block"
      class="m-t-15"
      @click="handleTransactionRetry"
    >
      {{ translations.trySendingMoneyAgain }}
    </bb-button>
  </div>
</template>

<script>
import { BbListItem, BbButton } from '@bigbank/interface-components'
import { formatMoneyWithCurrency } from '@/plugins/numformat'
import { mapState, mapActions } from 'pinia'
import dayjs from 'dayjs'
import { PaymentStatus } from './const'
import { useRootStore } from '../../../../store/root'
import { usePaymentStore } from '@account/store/paymentStore'
import { usePredefinedPaymentStore } from '@account/store/predefinedPaymentStore'
import { AccountRouteName } from '@account/const'

export default {
  components: {
    BbListItem,
    BbButton
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags', 'currency', 'locale']),
    ...mapState(usePaymentStore, ['paymentDetails', 'paymentStatus']),
    ...mapState(usePredefinedPaymentStore, ['getPredefinedById']),
    translations () {
      return {
        paymentSuccessful: this.$pgettext('payment_result', 'Payment successful!'),
        paymentFailed: this.$pgettext('payment_result', 'Payment failed!'),
        recipientName: this.$pgettext('payment_result', 'Recipient name'),
        recipientIban: this.$pgettext('payment_result', 'Recipient IBAN'),
        time: this.$pgettext('payment_result', 'Time'),
        description: this.$pgettext('payment_result', 'Description'),
        referenceNumber: this.$pgettext('payment_result', 'Reference number'),
        predefinedPaymentName: this.$pgettext('payment_result', 'Predefined payment name'),
        createPredefinedPayment: this.$pgettext('payment_result', 'Save as predefined payment'),
        modifyPredefinedPayment: this.$pgettext('payment_result', 'Overwrite values for predefined payment'),
        startNewTransaction: this.$pgettext('payment_result', 'Start new transaction'),
        trySendingMoneyAgain: this.$pgettext('payment_result', 'Try sending money again')
      }
    },
    paymentRequest () {
      return this.paymentDetails
    },
    isResultSuccess () {
      return this.paymentStatus === PaymentStatus.Success
    },
    isSaveTransactionAsButtonVisible () {
      return this.featureFlags.enablePredefinedPayments && this.isResultSuccess
    },
    saveButtonText () {
      if (this.predefinedPaymentId) {
        return this.translations.modifyPredefinedPayment
      } else {
        return this.translations.createPredefinedPayment
      }
    },
    titleText () {
      return this.isResultSuccess
        ? this.translations.paymentSuccessful
        : this.translations.paymentFailed
    },
    formattedPaymentAmount () {
      return formatMoneyWithCurrency(this.paymentDetails.amount, this.currency, this.locale)
    },
    formattedTime () {
      return dayjs(this.paymentRequest.createdTime ?? undefined).format('DD.MM.YYYY HH:mm')
    },
    paymentAmountColor () {
      return this.isResultSuccess
        ? 'mint'
        : 'red'
    },
    predefinedPaymentId () {
      return this.$route.query.predefinedPaymentId
    },
    predefinedPaymentName () {
      return this.getPredefinedById(this.predefinedPaymentId)?.name ?? ''
    }
  },
  methods: {
    ...mapActions(usePaymentStore, ['resetPaymentDetails', 'redirectToPaymentForm']),
    handleTransactionSaving () {
      if (this.predefinedPaymentId) {
        this.$router.push({
          name: AccountRouteName.PredefinedPayments,
          query: { predefinedPaymentId: this.predefinedPaymentId, fromPayment: true }
        })
      } else {
        this.$router.push({
          name: AccountRouteName.PredefinedPayments,
          query: { fromPayment: true }
        })
      }
    },
    handleNewTransaction () {
      this.resetPaymentDetails()
      if (this.predefinedPaymentId) {
        this.$router.push({ name: AccountRouteName.OutgoingPayments })
      }
      this.redirectToPaymentForm()
    },
    handleTransactionRetry () {
      this.redirectToPaymentForm()
    }
  }
}
</script>

<style scoped lang="scss">
.break-long-word {
  word-break: break-all;
}
</style>
