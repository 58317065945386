import axios from 'axios'
import { request, downloadFile } from '@/plugins/utils'
import { PrintoutTypeCode } from '@bigbank/dc-common/clients/http/account/account.enums'

export default {
  async markNotificationAsRead (uuid) {
    return await axios.delete(`/account/api/customers/me/notifications/${uuid}/read`, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json',
      withCredentials: true,
      credentials: 'include'
    })
  },
  async getCampaigns () {
    return await request('/account/api/campaigns/all', {
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  async markCampaignAsShown (campaignId) {
    return await request(`/account/api/campaigns/${campaignId}/mark-shown`, {
      method: 'PUT'
    })
  },
  getPrintoutList () {
    return request('/account/api/printouts/list')
  },
  generateDepositTaxReport (year, printoutTypeCode = PrintoutTypeCode.DepoInterestCertificate) {
    return request('/account/api/printouts/generate-deposit-interest-certificate', {
      method: 'POST',
      body: {
        year,
        printoutTypeCode
      }
    })
  },
  downloadTaxReport ({ downloadRequestId, fileName, contentType }) {
    return downloadFile(`/account/api/printouts/download/${downloadRequestId}`, fileName, { contentType })
  },
  downloadAccountStatement (searchQueryParams, fileType) {
    return downloadFile(
      `/account/api/account-statement/download/${encodeURIComponent(fileType.toLowerCase())}`,
      `statement.${fileType.toLowerCase()}`,
      {
        params: searchQueryParams
      }
    )
  },
  downloadStatementOfFees (searchQueryParams) {
    return downloadFile(
      '/account/api/account-statement/download/statement-of-fees',
      'statement_of_fees.pdf',
      {
        params: searchQueryParams
      }
    )
  },
  confirmPhoneChangeRequest (confirmRequestId, code) {
    return request(`/account/api/confirm/phone/${confirmRequestId}`, {
      method: 'PUT',
      body: {
        code
      },
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  confirmEmailChangeRequest (confirmRequestId) {
    return request(`/api/confirm-email/${confirmRequestId}`, {
      method: 'PUT',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getCustomerAccounts () {
    return request('/account/api/accounts', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getIdentificationDocuments () {
    return request('/account/api/identification-documents', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getDocumentRequirements () {
    return request('/account/api/document/requirements', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  uploadCompanyDocuments (files) {
    const formData = new FormData()
    files.forEach(f => {
      formData.append('file', f.file)
    })

    return request('/account/api/document/company', {
      method: 'post',
      body: formData,
      contentType: `multipart/form-data; boundary=${formData._boundary}`,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  uploadPrivateDocuments (files) {
    const formData = new FormData()
    files.forEach(f => {
      formData.append('file', f.file)
    })

    return request('/account/api/document/private', {
      method: 'post',
      body: formData,
      contentType: `multipart/form-data; boundary=${formData._boundary}`,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getAccountsForPayment () {
    return request('/account/api/payment/accounts', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getPaymentById (paymentId) {
    return request(`/account/api/payment/find/${paymentId}`, {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getAccountsForStatement () {
    return request('/account/api/account-statement/accounts', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getAccountStatement (searchQueryParams = {}) {
    return request('/account/api/account-statement', {
      params: searchQueryParams
    })
  },
  getAccountStatementSettings () {
    return request('/account/api/account-statement/settings', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  initPayment (body) {
    return request('/account/api/payment/init', {
      method: 'POST',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getAccountLimits (accountId) {
    return request(`/account/api/payment/account/${accountId}/limits`, {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getAccountVerificationStatus () {
    return request('/account/api/account-verification-data', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  initSigning (signingMethod, signingRequestId, additionalParams = {}) {
    return request(`/account/api/sign/${signingMethod}/init`, {
      method: 'POST',
      params: {
        signingRequestId
      },
      body: additionalParams,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  completeSigning (signingMethod, signingRequestId, additionalParams = {}) {
    return request(`/account/api/sign/${signingMethod}/complete`, {
      method: 'PUT',
      params: {
        signingRequestId
      },
      body: additionalParams,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getDashboardAccountsSummary () {
    return request('/account/api/dashboard/account-summary', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  initLimitsChange (body) {
    return request('/account/api/accounts/init-account-limits-change', {
      method: 'POST',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getAccountsLimits () {
    return request('/account/api/accounts/current-accounts-limits', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: false
      }
    })
  },
  validateBeneficiary (params) {
    return request('/account/api/payment/validate-beneficiary', {
      method: 'POST',
      body: {
        iban: params.iban,
        beneficiary: params.beneficiary
      }
    })
  },
  getTransactionDetails (transactionId) {
    return request(`/account/api/account-statement/transactions/${transactionId}`, {
      method: 'GET'
    })
  },
  getPredefinedPayments () {
    return request('/account/api/v1/predefined-payments', {
      method: 'GET',
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  getPredefinedPayment (predefinedPaymentId) {
    return request(`/account/api/v1/predefined-payments/${predefinedPaymentId}`, {
      method: 'GET',
      errHandlerOpts: { handleOnlyKnownErrors: true }
    })
  },
  deletePredefinedPayment (predefinedPaymentId) {
    return request(`/account/api/v1/predefined-payments/${predefinedPaymentId}`, {
      method: 'DELETE',
      errHandlerOpts: { handleOnlyKnownErrors: true }
    })
  },
  createPredefinedPayment (body) {
    return request('/account/api/v1/predefined-payments', {
      method: 'POST',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  modifyPredefinedPayment (predefinedPaymentId, body) {
    return request(`/account/api/v1/predefined-payments/${predefinedPaymentId}`, {
      method: 'PUT',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  },
  searchPendingPayments (body) {
    return request('/account/api/v1/pending-payments/search', {
      method: 'POST',
      body,
      errHandlerOpts: {
        handleOnlyKnownErrors: true
      }
    })
  }
}
