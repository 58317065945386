<template>
  <div class="predefined__main">
    <bb-container>
      <bb-row justify="between">
        <h4 class="f-color-navy f-medium"> {{ translations.title }}</h4>
        <bb-button
          v-if="!showCreateBanner"
          data-testid="add-new-predefined-button"
          color="black"
          inverted
          size="sm"
          :label="$screen.md ? translations.addNewDesktop : translations.addNewMobile"
          @click="createModalVisible = true"
        />
      </bb-row>
    </bb-container>
    <full-spinner v-if="predefinedPaymentsLoading"/>
    <div v-else>
      <no-predefined-payment-banner v-if="showCreateBanner" @create="createModalVisible = true"/>
      <predefined-payment-table v-else @onDelete="onDelete"/>
    </div>
    <predefined-payment-modal v-if="createModalVisible" :visible="createModalVisible" @close="closeCreateModal"/>
    <delete-predefined-payment-modal
      v-if="deleteModalVisible"
      :visible="deleteModalVisible"
      :predefined-payment-id="deletePredefinedId"
      @close="closeDeleteModal"/>
  </div>
</template>

<script>
import NoPredefinedPaymentBanner from '@account/views/payments/predefined/CreatePredefinedPaymentBanner.vue'
import { mapActions, mapState } from 'pinia'
import { usePredefinedPaymentStore } from '@account/store/predefinedPaymentStore'
import FullSpinner from '@/layouts/FullSpinner.vue'
import PredefinedPaymentModal from '@account/views/payments/predefined/PredefinedPaymentModal.vue'
import PredefinedPaymentTable from '@account/views/payments/predefined/PredefinedPaymentTable.vue'
import DeletePredefinedPaymentModal from '@account/views/payments/predefined/DeletePredefinedPaymentModal.vue'

export default {
  components: {
    DeletePredefinedPaymentModal,
    PredefinedPaymentTable,
    PredefinedPaymentModal,
    NoPredefinedPaymentBanner,
    FullSpinner
  },
  data () {
    return {
      createModalVisible: false,
      deleteModalVisible: false,
      deletePredefinedId: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        if (to?.query?.predefinedPaymentId || to?.query?.fromPayment) {
          this.createModalVisible = true
        }
      }
    }
  },
  computed: {
    ...mapState(usePredefinedPaymentStore, ['predefinedPayments', 'predefinedPaymentsLoading']),
    translations () {
      return {
        title: this.$pgettext('predefined_payment', 'European payments'),
        addNewDesktop: this.$pgettext('predefined_payment', 'Add new payment'),
        addNewMobile: this.$pgettext('predefined_payment', 'Add new')
      }
    },
    showCreateBanner () {
      return this.predefinedPayments.length === 0
    }
  },
  methods: {
    ...mapActions(usePredefinedPaymentStore, ['getPredefinedPayments']),
    closeCreateModal () {
      this.createModalVisible = false
      if (this.$route.query.predefinedPaymentId || this.$route.query.fromPayment) {
        this.$router.replace({ query: { predefinedPaymentId: undefined, fromPayment: undefined } })
      }
    },
    onDelete (predefinedPaymentId) {
      this.deleteModalVisible = true
      this.deletePredefinedId = predefinedPaymentId
    },
    closeDeleteModal () {
      this.deleteModalVisible = false
      this.deletePredefinedId = null
    }
  },
  created () {
    this.getPredefinedPayments()
  }
}
</script>

<style lang="scss" scoped>
.predefined {
  &__main {
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: $mobile-view-breaking-point) {
      margin-top: 15px;
    }
  }
}
</style>
