<template>
  <layout-with-menu>
    <div class="payments-layout" id="payment-layout-title">
      <hero-header :title="translations.title" :backButtonVisible="false">
        <bb-horizontal-menu :isStickyOnMobile="true" :routes="menuRoutes"/>
      </hero-header>
      <div class="payments-layout__main">
        <router-view :key="this.currentRoute + $route.query.predefinedPaymentId"/>
      </div>
    </div>
  </layout-with-menu>
</template>

<script>
import HeroHeader from '@/components/hero-header/HeroHeader'
import { AccountRouteName } from '@account/const'
import { mapState } from 'pinia'
import { useRootStore } from '@/store/root'

export default {
  components: {
    HeroHeader
  },
  data () {
    return {
      currentRoute: this.$router.currentRoute.name
    }
  },
  computed: {
    ...mapState(useRootStore, ['featureFlags']),
    translations () {
      return {
        title: this.$pgettext('payment_form', 'Payments')
      }
    },
    menuRoutes () {
      return [
        {
          path: `/${AccountRouteName.OutgoingPayments}`,
          title: this.$pgettext('payment_form', 'Outgoing payment'),
          active: this.currentRoute === AccountRouteName.OutgoingPayments
        },
        {
          path: `/${AccountRouteName.PredefinedPayments}`,
          title: this.$pgettext('predefined_payment', 'Predefined payment'),
          active: this.currentRoute === AccountRouteName.PredefinedPayments,
          hide: !this.featureFlags.enablePredefinedPayments
        },
        {
          path: `/${AccountRouteName.PendingPayments}`,
          title: this.$pgettext('pending_payment', 'Pending payment'),
          active: this.currentRoute === AccountRouteName.PendingPayments,
          hide: !this.featureFlags.enablePendingPayments
        }
      ].filter(({ hide }) => !hide)
    }
  },
  watch: {
    '$route' (to) {
      this.currentRoute = to.name
    }
  }
}
</script>
<style lang="scss" scoped>
$container-max-width: 1140px;

.payments-layout {
  &__main {
    max-width: $container-max-width;
    margin: auto;
  }
}
</style>
