import { useAccountStore } from '@account/store/accountStore'
import { useDepositStore } from '@deposits/store/depositStore'
import { useLoanStore } from '@loan/store/loanStore'
import { useRootStore } from '@/store/root'

export const featureRouteGuard = function (feature, route = '/oops/404', piniaStoreName) {
  return async (to, from, next) => {
    let store

    if (piniaStoreName === 'deposit') {
      store = useDepositStore()
    } else if (piniaStoreName === 'loan') {
      store = useLoanStore()
    } else if (piniaStoreName === 'account') {
      store = useAccountStore()
    } else {
      store = useRootStore()
    }

    if (Array.isArray(feature) && store.featureFlags) {
      for (const feat of feature) {
        if (store.featureFlags[feat]) {
          next(true)
          return
        }
      }
      next(route)
    } else {
      next(!!store.featureFlags?.[feature] || route)
    }
  }
}

/**
 * Used for in-component route guard. Delays the guard until the component is loaded and the feature flags are available.
 *
 * @param {*} feature feature or array of features to check
 * @param {*} route fallback route
 * @param {*} piniaStoreName store name to use
 * @returns void
 */
export const componentFeatureRouteGuard = function (feature, route = '/oops/404', piniaStoreName) {
  return async (to, from, next) => {
    return next(() => featureRouteGuard(feature, route, piniaStoreName)(to, from, next))
  }
}

export const transactionalRouteGuard = function () {
  return (to, _from, next) => {
    const rootStore = useRootStore()
    const areAllowed = rootStore.checkTransactionsAllowed()

    if (!areAllowed) {
      rootStore.setPreviousRoute(to)
      next(false)
    } else {
      next(true)
    }
  }
}
