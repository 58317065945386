<template>
  <div class="pending__main">
    <bb-container>
      <bb-row justify="between">
        <h4 class="f-color-navy f-medium"> {{ translations.title }}</h4>
      </bb-row>
    </bb-container>
    <full-spinner v-if="isPendingPaymentsLoading"/>
    <div v-else>
      <create-pending-payment-banner v-if="showCreateBanner" @create="createModalVisible = true"/>
    </div>
  </div>
</template>

<script>
import CreatePendingPaymentBanner from './CreatePendingPaymentBanner.vue'
import { mapActions, mapState } from 'pinia'
import FullSpinner from '@/layouts/FullSpinner.vue'
import { usePendingPaymentStore } from '@account/store/pendingPaymentStore'

const PENDING_PAYMENT_PAGE_SIZE = 50
const PENDING_PAYMENT_PAGE_NUMBER = 1

export default {
  components: {
    CreatePendingPaymentBanner,
    FullSpinner
  },
  data () {
    return {
      createModalVisible: false
    }
  },
  computed: {
    ...mapState(usePendingPaymentStore, ['isPendingPaymentsLoading', 'pendingPayments']),
    translations () {
      return {
        title: this.$pgettext('pending_payment', 'Pending payment')
      }
    },
    showCreateBanner () {
      return this.pendingPayments.length === 0
    }
  },
  methods: {
    ...mapActions(usePendingPaymentStore, ['searchPendingPayments'])

  },
  created () {
    this.searchPendingPayments({
      pageNumber: PENDING_PAYMENT_PAGE_NUMBER,
      pageSize: PENDING_PAYMENT_PAGE_SIZE
    })
  }
}
</script>

<style lang="scss" scoped>
.pending {
  &__main {
    margin-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: $mobile-view-breaking-point) {
      margin-top: 15px;
    }
  }
}
</style>
