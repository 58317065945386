<template>
  <schedule v-bind="scheduleProps" />
</template>

<script>
import Schedule from '@loan/components/ScheduleTemplate'
import api from '@loan/api'
import { mapState } from 'pinia'
import { useLoanStore } from '@loan/store/loanStore'
import { componentFeatureRouteGuard } from '@/plugins/routeGuards'

export default {
  name: 'loan-schedule',
  components: { Schedule },
  data () {
    return {
      contractId: null
    }
  },
  computed: {
    ...mapState(useLoanStore, ['contract']),
    scheduleProps () {
      return {
        loadData: this.loadData,
        contractId: this.contractId,
        contract: this.contract,
        hasProgress: false,
        translationOverrides: {
          scheduleTypes: {
            PAID: this.$pgettext('invoice_status_paid', 'Paid'),
            PAID_LATE: this.$pgettext('invoice_status_paid_late', 'Paid after due date'),
            DEBT: this.$pgettext('invoice_status_debt', 'Payment delayed'),
            SCHEDULED: this.$pgettext('invoice_status_scheduled', 'Invoice'),
            CURRENT: this.$pgettext('invoice_status_current', 'Invoice')
          },
          modal: {
            title: {
              debt: this.$pgettext('modal_title_invoice', 'Invoice details'),
              invoice: this.$pgettext('modal_title_invoice', 'Invoice details'),
              paid: this.$pgettext('modal_title_invoice', 'Invoice details'),
              'paid-late': this.$pgettext('modal_title_invoice', 'Invoice details')
            }
          },
          emptyText: this.$pgettext('invoices', 'There are currently no invoices. As soon as you have new invoices they appear here.')
        }
      }
    }
  },
  methods: {
    loadData () {
      return api.getInvoices(this.contractId)
    }
  },
  created () {
    this.contractId = this.$route.params.contractId
  },
  beforeRouteEnter: componentFeatureRouteGuard(['enableContractDetails', 'enableContractDetailsForRevolvingLoan'], undefined, 'loan')
}
</script>
